import React from 'react'
import './IconHeader.css'
import LogoImg from '../assets/LogoCompany.png';
import { MDBView, MDBMask } from 'mdbreact';
const IconHeader = () => {

  return (


<img  src={LogoImg} style={{padding:"8px",height:"80px"}} />





  )
}

export default IconHeader;
